/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    blockquote: "blockquote",
    p: "p",
    em: "em",
    a: "a",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "„Mes pusę dienos gerdami diskutavom apie dalykus.“"), "\n"), "\n", React.createElement(_components.p, null, "Ir viskas prasidėjo nuo nuosprendžio sau, kuris sakė, kad daryt reikia.\nIš to reikalo daryt, dar būtų gerai ir rodyt ", React.createElement(_components.em, null, "kaip"), ", ", React.createElement(_components.em, null, "kam"), " ir ", React.createElement(_components.em, null, "kodėl"), "."), "\n", React.createElement(_components.p, null, "Taip ir gimė ataskaita pradedant projektu „aš“. Su Aurelijum\npasikalbėjus apie bendražygių aktyvumą siekiant nirvanos ir profesinio\npraregėjimo nusprendžiau, kad mano amžinas noras mokytis ir mokyti\nturėtų būti atgaivintas. Ir fortūna išsišiepė."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Are there any graphic design podcasts? I’d really like to listen to a\nshow that rounds up latest work, interviews with studios etc."), "\n", React.createElement(_components.p, null, "— Mark Boulton (@markboulton) ", React.createElement(_components.a, {
    href: "https://twitter.com/markboulton/status/506422702673264641"
  }, "September 1, 2014")), "\n"), "\n", React.createElement(_components.p, null, "Čiūvai iš ", React.createElement(_components.a, {
    href: "http://www.throughprocess.com/"
  }, "Through Process"), " kalba apie\nmokymą, darymą ir ", React.createElement(_components.a, {
    href: "http://walking.designcrit.com/"
  }, "rodymą rezultatų"), ".\nGal tik ne visada procesas randa vietą tarp to rodymo, bet tai\npakeičiama. Bet kokiu atveju, „Through Process“ būtina paklausyti, ar\nbent jau peržvelgt autorių biografijas."), "\n", React.createElement(_components.p, null, "Apie procesą plačiau kalba ", React.createElement(_components.a, {
    href: "https://www.youtube.com/user/theartassignment"
  }, "The Art\nAssignment"), "."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "Aš sakau, kad darau ", React.createElement(_components.em, null, "frontend’ą"), ", nors iš tiesų visada buvau dizainan\nlinkęs. CSS’ai ir JS’ai tiesiog padeda papasakoti klausytojui mano mintį\ntokioje medijoje, kurioje gyvens galutinis rezultatas. O dabar jau pats\nlaikas rimčiau pasinerti į grafiką. Sakau „į grafiką“ tik dėl to, kad\nabi veiklos: ir grafikos dizainas, ir programavimas – labai jau\npavaldžios dizainui."), "\n", React.createElement(_components.p, null, "Siekiant bendruomenės, kuri norėtų mokytis ir mokyti, proceso rodymas ir\njo ", React.createElement(_components.a, {
    href: "http://pbs.twimg.com/media/BwjddRsCUAAw_fl.png:large"
  }, "ardymas prieš\nvisus"), " man atrodo\nsvarbiausia dalim. Iki šiol vis dar nerodau Viltės reikalų, savo\npasivaikščiojimų ir savo blogo (ataskaita turėtų tapti minčių kratiniu,\no bloge – plačiau nagrinėjamos konkrečios mintys)."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "Yra veikėjas pavadinimu ", React.createElement(_components.a, {
    href: "http://datenform.de/index-hold.html"
  }, "Aram\nBartholl"), ", kuris turi mintį\n", React.createElement(_components.a, {
    href: "http://datenform.de/offline-art-new2.html"
  }, React.createElement(_components.em, null, "offline"), " menui"), " – žiauriai\nnoriu tokių prisėt mieste."), "\n", React.createElement(_components.p, null, "Kitas veikėjas – Malwin Béla Hürkey ", React.createElement(_components.a, {
    href: "https://www.behance.net/gallery/19004863/Nihon-Typeface"
  }, "priminė mintį, kad aš turiu kelis\nšriftus kurių taip niekad ir\nneišdirbau"), ",\nnes tada dar nežinojau, kad procesas yra vienodai svarbus lyginant su\nrezultatu. Anksčiau rezultatas manęs nedomino. Būtų smagu juos\n", React.createElement(_components.em, null, "revisit"), "’int."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
